<template>
  <div v-if="isMEMSHAKAgent">
    <Banner :banner="bannerImages"/>
    <!-- <div class="my-5 mx-1 hotelplusflight" v-if="!passState" dir="ltr">
      <div class="container">
        <div class="m-auto" style="max-width: fit-content;">
          <h5 v-if="authErrorMessage" class="text-center" style="color: red;">
            {{ authErrorMessage }}
          </h5>
          <b-spinner v-if="isPassChecking"
            variant="light"
            type="grow"
          ></b-spinner>
          <b-form inline @submit.stop.prevent v-else>
            <label for="pnr-input">Password:</label>
            <b-form-input
              id="password-input"
              class="mx-2 mb-sm-0"
              v-model="password"
              type="text"
              @keypress="pressEnter($event)"
            ></b-form-input>
            <b-button variant="primary" @click="checkPassState">{{$t('cancel-booking.send')}}</b-button>
          </b-form>

        </div>
      </div>v-else
    </div> -->
    <div class="my-5 mx-1 hotelplusflight" >
      <div class="container">
        <div class="m-auto" style="max-width: fit-content;">
          <b-form inline @submit.stop.prevent>
            <label for="pnr-input">Pnr:</label>
            <b-form-input
              id="pnr-input"
              class="mx-2 mb-sm-0"
              v-model="cancelPnr"
              type="text"
            ></b-form-input>

            <b-form-checkbox class="mx-2 mr-sm-2 mb-sm-0" v-model="isExternal">{{$t('cancel-booking.is-external')}}</b-form-checkbox>

            <b-button variant="primary" @click="sendPnr">{{$t('cancel-booking.send')}}</b-button>
          </b-form>

        </div>
      </div>
    </div>
    <Footer page="home"/>

    <b-modal id="detailModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h5 class="m-auto">{{ $t('cancel-booking.cancel-info-detail') }}</h5>
        </div>
      </template>
      <template>
        <b-alert variant="warning" :show="true" :class="{'text-right': lang === 'he'}" v-if="isMultipleData">
          <p>{{ $t("cancel-booking.message-select-booking") }}</p>
        </b-alert>
        <b-form-group :label="`${$t('cancel-booking.select-booking')}:`" v-if="!!cancelBookingInfo" :class="{'text-right': lang === 'he'}">
          <b-form-select
            v-model="selectedBooking"
            :options="bookingList"
          >
          </b-form-select>
        <b-alert variant="warning" :show="true" :class="{'text-right': lang === 'he'}" v-if="isMultipleReference" class="mt-3 pb-0">
          <p>{{ $t("cancel-booking.message-select-reference") }}</p>
        </b-alert>
          <b-form-select
            v-model="extPnr"
            :options="referenceList"
          >
          </b-form-select>
        </b-form-group>
        <b-alert variant="success" :show="true" v-if="!!hotelData" :class="{'text-right': lang === 'he'}">
          <p><span>{{$t("cancel-booking.hotel-items.hotel-name")}}:</span> <span>{{ hotelData.hotelName }}</span></p>
        </b-alert>
        <b-alert variant="info" :show="true" v-if="selectedBooking && cancelMoreInfo" :class="{'text-right': lang === 'he'}">
          <p><span>{{$t("cancel-booking.hotel-items.hotel-order-num")}}:</span> <span>{{ cancelMoreInfo.hotelOrderNum }}</span></p>
          <p><span>{{$t("cancel-booking.hotel-items.total-price")}}:</span> <span>{{ cancelMoreInfo.total }}</span></p>
          <p><span>{{$t("cancel-booking.hotel-items.basis")}}:</span> <span>{{ cancelMoreInfo.basis }}</span></p>
          <p><span>{{$t("cancel-booking.hotel-items.cancellation-policy")}}:</span> <span>{{ cancelMoreInfo.cancellationPolicy }}</span></p>
          <p><span>{{$t("cancel-booking.hotel-items.check-in")}}:</span> <span>{{ cancelMoreInfo.hotel[0].checkIn }}</span></p>
          <p><span>{{$t("cancel-booking.hotel-items.check-out")}}:</span> <span>{{ cancelMoreInfo.hotel[0].checkOut }}</span></p>
          <p><span>{{$t("cancel-booking.hotel-items.status")}}:</span> <span>{{ cancelMoreInfo.hotel[0].status }}</span></p>
          <p><span>{{$t("cancel-booking.hotel-items.hotel-remark")}}:</span> <span>{{ cancelMoreInfo.hotel[0].remark }}</span></p>
          <p><span>{{$t("cancel-booking.hotel-items.hotel-ex-code")}}:</span> <span>{{ cancelMoreInfo.hotel[0].hotelExCode }}</span></p>
        </b-alert>
        <b-alert variant="danger" :show="true" v-if="selectedBooking && cancelMoreInfoError" :class="{'text-right': lang === 'he'}">
          <p v-html="cancelMoreInfoError.message"></p>
        </b-alert>

        <b-alert variant="danger" :show="true" :class="{'text-right': lang === 'he'}" v-if="isErrorGetCancelInfo">
          <p>{{ isErrorGetCancelInfo.message }}</p>
        </b-alert>
        <b-alert variant="danger" :show="true" :class="{'text-right': lang === 'he'}" v-if="!selectedBooking || isNoReference">
          <p>{{ $t('cancel-booking.no-detail') }}</p>
        </b-alert>
        <b-alert variant="danger" :show="true" :class="{'text-right': lang === 'he'}" v-if="isCanceled">
          <p>{{ $t('cancel-booking.canceled-already') }}</p>
        </b-alert>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="danger" @click="permitCancel" v-if="!!selectedBooking && !isCanceled">
          {{ $t("cancel-booking.cancel-permit") }}
        </b-button>
        <b-button size="lg" variant="success" @click="resetData();ok();">
          {{ $t("cancel-booking.cancel-close") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="resultModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('cancel-booking.cancel-result') }}</h3>
        </div>
      </template>
      <template>
        <b-alert variant="success" :show="true" v-if="cancelState && cancelState.data && cancelState.data.succeeded" :class="{'text-right': lang === 'he'}">
          <p>{{ $t('cancel-booking.succeeded-canceled') }}</p>
        </b-alert>

        <h3 v-if="cancelState && ((cancelState.data && !cancelState.data.succeeded) || cancelState.error || !!validationError)" :class="{'text-right': lang === 'he'}"> {{ $t('cancel-booking.error-title') }}</h3>

        <b-alert variant="warning" :show="true" v-if="cancelState && cancelState.data && !cancelState.data.succeeded" :class="{'text-right': lang === 'he'}">
          <p>{{ $t('cancel-booking.cancellation-rejected') }}</p>
          <p v-html="cancelState.data.reason"></p>
        </b-alert>
        <b-alert variant="danger" :show="true" v-if="cancelState && !!cancelState.error" :class="{'text-right': lang === 'he'}">
          <p>{{ $t('cancel-booking.error-occurred') }}</p>
          <p v-html="cancelState.error.message"></p>
        </b-alert>
        <b-alert variant="danger" :show="true" v-if="!!validationError" :class="{'text-right': lang === 'he'}">
          <p>{{ validationError }}</p>
        </b-alert>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="resetData();ok();">
          {{ $t("cancel-booking.cancel") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BForm, BFormCheckbox,
  // BSpinner,
  BAlert,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue';
import getProductBanner from '@/mixins/getProductBanner';

export default {
  name: 'CancelBookingPage',
  mixins: [getProductBanner],
  components: {
    BForm,
    BFormCheckbox,
    // BSpinner,
    BAlert,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Footer: () => import('@/components/content/Footer'),
    Banner: () => import('@/components/productPage/ProductBanner'),
  },
  data() {
    return {
      cancelPnr: '',
      isExternal: false,
      selectedBooking: null,
      extPnr: '',
      validationError: '',
      isNoReference: false,

      // password: '',
      // isPassChecking: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
      hotelList: 'GET_CAMINGO_HOTELS',
      // odysseyAgentState: 'GET_ODYSSEY_AGENT_STATE',

      cancelBookingInfo: 'GET_CANCEL_BOOKING_INFO',
      cancelState: 'GET_CANCEL_STATE',
      cancelBookingMoreInfo: 'GET_CANCEL_BOOKING_MORE_INFO',
      cancelCorrExtPnr: 'GET_CANCEL_CORRESPONDING_EXT_PNRS',
      // passState: 'GET_PASSWORD_STATE',
      // authErrorMessage: 'GET_PASS_ERROR_MESSAGE',
    }),
    bookingList() {
      const { cancelBookingInfo, cancelCorrExtPnr } = this;
      if (!cancelBookingInfo && !cancelCorrExtPnr) return [];

      return cancelBookingInfo.map((dt, inx) => ({
        value: dt || cancelCorrExtPnr[inx],
        text: dt?.no || cancelCorrExtPnr[inx].fcPnr,
      })) || [];
    },
    referenceList() {
      const { selectedBooking } = this;
      if (!selectedBooking) return [];

      if (selectedBooking.products) {
        return selectedBooking.products.order
          .filter((dt) => dt.reference)
          .map((dt) => ({
            value: this.parseCode(dt.reference),
            text: `${this.parseCode(dt.reference)}-${dt.lineNo}`,
          }));
      } else {
        return selectedBooking.extPnr
          .map((dt, inx) => ({
            value: this.parseCode(dt),
            text: `${this.parseCode(dt)}-${inx + 1}`,
          }));
      }
    },
    isMultipleData() {
      return this.bookingList.length > 1;
    },
    isMultipleReference() {
      return this.referenceList.length > 1;
    },
    isErrorGetCancelInfo() {
      const { cancelBookingInfo } = this;
      if (!cancelBookingInfo) return null;
      return cancelBookingInfo?.error || null;
    },
    hotelData() {
      const { hotelList, selectedBooking, extPnr } = this;
      if (!hotelList || !selectedBooking || !extPnr) return null;

      const ordersIndex = (selectedBooking?.products?.order)
        ? selectedBooking.products.order.findIndex((od) => this.parseCode(od.reference) === extPnr)
        : selectedBooking.extPnr.findIndex((od, inx) => `${this.parseCode(od)}-${inx + 1}` === extPnr);

      if (ordersIndex === -1) return null;
      const { productId, hotelCode } = selectedBooking.products.order[ordersIndex];

      const hotelCamingoCode = productId === '-1' ? hotelCode : productId;
      return hotelList?.data?.find((ht) => ht.hotelCamingoCode === hotelCamingoCode) || null;
    },
    cancelMoreInfo() {
      const { cancelBookingMoreInfo } = this;
      if (!cancelBookingMoreInfo) return null;
      return cancelBookingMoreInfo.data;
    },
    cancelMoreInfoError() {
      const { cancelBookingMoreInfo } = this;
      if (!cancelBookingMoreInfo) return null;
      return cancelBookingMoreInfo.error;
    },
    isCanceled() {
      const { cancelBookingMoreInfo } = this;
      if (!cancelBookingMoreInfo) return false;
      return cancelBookingMoreInfo.isCanceled;
    },
  },
  watch: {
    bookingList() {
      this.selectedBooking = this.bookingList[0]?.value;
    },
    selectedBooking() {
      this.extPnr = this.selectedBooking?.products
        ? this.parseCode(this.selectedBooking?.products?.order[0].reference)
        : this.parseCode(this.selectedBooking?.extPnr[0]) || '';
    },
    extPnr() {
      if (this.cancelBookingInfo && this.cancelBookingInfo[0]?.no) {
        this.fetchMoreBookingInfo();
      }
    },
    cancelPnr() {
      this.resetData();
    },
  },
  async created() {
    if (!this.isMEMSHAKAgent) {
      this.$router.push('/');
    } else {
      this.$store.commit('SET_CURRENT_PAGE', 'booking-cancel');
      if (!this.hotelList.data) await this.$store.dispatch('FETCH_CAMINGO_HOTELS');
      // this.password = sessionStorage.getItem('cancelPassword');
      // this.checkPassState();
    }
  },
  methods: {
    resetData() {
      this.isExternal = false;
      this.isNoReference = false;
      this.validationError = '';
      this.$store.dispatch('RESET_CANCEL_MORE_INFO');
    },
    async sendPnr() {
      const pnr = this.cancelPnr.trim();
      const body = {
        pnr,
        isExternal: this.isExternal,
      };
      if (pnr !== '') {
        await this.$store.dispatch('SEND_CANCEL_PNR_FOR_GET_INFO', body);
        this.$bvModal.show('detailModal');
      }
    },
    async permitCancel() {
      if (this.selectedBooking && this.extPnr) {
        this.$bvModal.msgBoxConfirm(this.$t('cancel-booking.cancel-confirm'), {
          title: this.$t('login.confirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('login.yes'),
          cancelTitle: this.$t('login.no'),
          footerClass: 'p-2',
          contentClass: 'text-left',
          hideHeaderClose: true,
          centered: true,
        })
          .then(async (value) => {
            if (value) {
              await this.$store.dispatch('SUBMIT_CANCEL_PERMISSION', {
                pnr: this.selectedBooking.no || this.selectedBooking.fcPnr,
                extPnr: this.extPnr,
                // password: this.password,
              });
              this.$bvModal.hide('detailModal');
              this.$bvModal.show('resultModal');
              this.extPnr = null;
            }
          });
      } else {
        this.validationError = this.$t('cancel-booking.no-ext-pnr');
        this.$bvModal.show('resultModal');
      }
    },
    // async checkPassState() {
    //   if (this.password) {
    //     this.isPassChecking = true;
    //     await this.$store.dispatch('CHECK_CANCELLATION_PASSWORD', { password: this.password });
    //     this.isPassChecking = false;
    //   }
    // },
    // pressEnter(event) {
    //   if (event.charCode === 13) {
    //     this.checkPassState();
    //   } else {
    //     this.$store.commit('SET_PASS_ERROR_MESSAGE', '');
    //   }
    // },
    parseCode(code) {
      return code?.trim().split(';')[0] || '';
    },
    fetchMoreBookingInfo() {
      this.resetData();
      if (!this.hotelData) return;
      const body = {
        supplierCode: this.hotelData.source,
        externalCode: this.hotelData.hotelExternalCode,
        extPnr: this.extPnr,
      };
      if (this.extPnr && this.hotelData) { this.$store.dispatch('FETCH_MORE_DETAIL_FOR_CANCEL_BOOKING', body); } else { this.isNoReference = true; }
    },
  },
};
</script>

<style>
  .price-compare.modal-header .close {
    margin: -1rem auto -1rem -1rem;
  }
  .price-message-body {
    font-size: 1.2rem;
  }
</style>

<style scoped>
.please_mall {
  width: 100%;
  text-align: center;
}
.please_mall .icon_box {
  width: 88px;
  height: 82px;
  border: 1px solid #0763ab;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 15px;
}
.please_mall h5 {
  text-transform: uppercase;
  color: #000;
  margin: 0 0;
  font-size: 16px;
}
</style>
