<template>
  <div>
    <BreadCrumb type="area" :data="linkData" v-if="linkData" />
    <Banner :banner="bannerImages"/>
    <div class="my-5 hotelplusflight">
      <div class="container">
        <div class="row">
          <div :class="['filter-cont', 'dVacation-pack', hotel ? 'col-md-9' : 'col-md-12']">
            <router-view @setBookingStage="setBookingStage" />
          </div>
          <Sidebar :remarks="remarks" :hotelName="hotelName" :destination="destinationName" v-if="hotel" />
        </div>
      </div>
    </div>
    <Footer page="booking" v-if="!hideCondition"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { fromBase64 } from '@/utils/base64Util';
import getProductBanner from '@/mixins/getProductBanner';

export default {
  mixins: [getProductBanner],
  components: {
    Banner: () => import('@/components/productPage/ProductBanner'),
    BreadCrumb: () => import('@/components/productPage/Breadcrumb'),
    Sidebar: () => import('@/components/productPage/ProductSidebar'),
    Footer: () => import('@/components/content/Footer'),
  },
  data() {
    return {
      product: null,

      hotel: [],

      bookingStage: 2,
      stageLabel: ['', 'product-page.package-details', 'product-page.payment-details', 'product-page.Order-Completion'],
      remarks: '',
      hotelName: '',
      destinationName: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
    }),
    linkData() {
      return {
        text: this.hotelName,
        dest: this.destinationName,
        stage: this.$t(this.stageLabel[this.bookingStage]),
      };
    },
    hideCondition() {
      const { isOdysseySite } = this;
      return isOdysseySite;
    },
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  methods: {
    async fetchData() {
      this.product = JSON.parse(fromBase64(window.localStorage.getItem('production')));

      this.hotel = this.product.hotel;
      this.updateLabelWithLang();
      this.hotelName = this.hotel.hotelName;
      this.destinationName = this.hotel.cityName;
    },
    setBookingStage(pStage) {
      this.bookingStage = pStage;
    },
    updateLabelWithLang() {
      this.remarks = '';
    },
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'booking');
    this.fetchData();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.retingbox .rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
  padding: 0;
  width: auto;
}
.clearfix {
  clear: both;
}
.hotelplusflight_body,
.mobail_part {
  display: none;
}

@media (max-width: 479px) {
  .holiday-hotel-details,
  .facilitie-list,
  .price-composition-desktop {
    display: none;
  }
  .hotelplusflight_body,
  .mobail_part {
    display: block;
  }
  .product-page-flight .filterItem {
    margin: 0;
  }
  .dVacation-pack {
    padding: 10px;
  }
  .my-5.hotelplusflight {
    margin-top: 0px!important;
    margin-bottom: 0px !important;
  }

}

@media ( max-width:767.98px){
  .hotelplusflight > .container{
    max-width : 100%;
  }
}

@media ( max-width : 991.98px){
  .sideBar{
    display: none;
  }

  .dVacation-pack{
    max-width: 100%;
  }

}

@media (min-width: 768px) and (max-width:991.98px) {
  .filter-cont.dVacation-pack{
      flex : none !important;
  }
}
</style>
