<template>
  <div>
    <ContentLoading type="app" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'siteMap',
  components: {
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_CAMINGO_DESTINATION',
      chainList: 'GET_HOTEL_CHAIN_TABLE',
      destDataForLp: 'GET_DEST_DATA_FOR_LP',
      activeChainIdList: 'GET_HOTEL_ACTIVE_CHAIN',
      categoryList: 'GET_HOTEL_CATEGORY',
      staticPageList: 'GET_FOOTER_PAGES_LIST',
      hotelList: 'GET_CAMINGO_HOTELS',
    }),
    activeChain() {
      return this.chainList.filter((chain) => this.activeChainIdList.indexOf(chain.id) >= 0);
    },
    xmlContent() {
      let xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
      xml += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';

      const { destinationList, chainList, destDataForLp, activeChain, categoryList, staticPageList, hotelList } = this;
      // hotels-page(city)
      if (destinationList) {
        destinationList.forEach((item) => {
          xml += `${this.makeUrlDom(`/hotels-page/?city=${item.code}`)}\n`;
        });
      }

      // hotels-page(chain)
      if (chainList) {
        chainList.forEach((item) => {
          xml += `${this.makeUrlDom(`/hotels-page/?chain=${item.id}`)}\n`;
        });
      }

      // destination (landing page)
      if (destDataForLp) {
        destDataForLp.forEach((item) => {
          xml += `${this.makeUrlDom(`/?destination=${item}`)}\n`;
        });
      }

      // chain (landing page)
      if (activeChain) {
        activeChain.forEach((item) => {
          xml += `${this.makeUrlDom(`/?chain=${item.name}`)}\n`;
        });
      }

      // DealType (Deal category)
      if (categoryList) {
        categoryList.forEach((item) => {
          xml += `${this.makeUrlDom(`/?dealType=${item.name}`)}\n`;
        });
      }

      // static pages
      if (staticPageList) {
        Object.entries(staticPageList).forEach((item) => {
          xml += `${this.makeUrlDom(`/${item[1].slug}`)}\n`;
        });
      }

      // service pages
      xml += (`${this.makeUrlDom('/אודות_')}\n`);
      xml += (`${this.makeUrlDom('/צור_קשר')}\n`);
      xml += (`${this.makeUrlDom('/מדיניות_פרטיות')}\n`);
      xml += (`${this.makeUrlDom('/מידע_לנוסע')}\n`);
      xml += (`${this.makeUrlDom('/תקנון_ביטול_וזיכוי')}\n`);
      xml += (`${this.makeUrlDom('/תנאים_כללים_והגבלת_אחריות')}\n`);
      xml += (`${this.makeUrlDom('/תקנון_מועדון_לקוחות_האתר')}\n`);
      xml += (`${this.makeUrlDom('/מדריך_היעדים')}\n`);
      xml += (`${this.makeUrlDom('/בקשה_לביטול_הזמנה')}\n`);
      xml += (`${this.makeUrlDom('/הרשמה_לרשימת_תפוצה')}\n`);

      // hotel info pages
      if (hotelList.data) {
        hotelList.data.forEach((item) => {
          xml += `${this.makeUrlDom(`/hotel-info/?city=${item.mainCityCode}&amp;hotelCode=${item.hotelExternalCode}`)}\n`;
        });
      }

      xml += '</urlset>';

      return `data:text/csv;charset=utf-8,${xml}`;
    },
  },
  mounted() {
  },
  data() {
    return {
    };
  },
  async created() {
    await this.$store.dispatch('FETCH_DEST_DATA_FOR_LP');
    await this.$store.dispatch('FETCH_CAMINGO_HOTELS');
    // console.log(this.xmlContent);

    this.downloadXml();
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  },
  methods: {
    makeUrlDom(url) {
      return `<url><loc>https://flyingisr.com/${url}</loc><lastmod>${this.currentDate()}</lastmod></url>`;
    },
    currentDate() {
      const date = new Date().toJSON().slice(0, 10);
      return date;
    },
    downloadXml() {
      const encode = encodeURI(this.xmlContent);
      const link = document.createElement('a');
      link.setAttribute('href', encode);
      link.setAttribute('download', 'sitemap.xml');
      document.body.appendChild(link);

      link.click();
    },
  },
};
</script>
