<template>
  <div>
    <div v-if="loading">
      <content-loading type="product" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
    }),
  },
  watch: {
    isLoading() {
      this.loading = this.isLoading;
    },
  },
  mounted() {
    const { marketerId, subMarketerId } = JSON.parse(window.localStorage.getItem('marketer'));
    window.localStorage.removeItem('marketer');
    if (marketerId && marketerId !== '') this.$store.commit('SET_MARKETER_ID', marketerId);
    if (subMarketerId && subMarketerId !== '') this.$store.commit('SET_SUB_AGENCY', subMarketerId);
    let path = window.location.href.replace('redirect-verified', 'booking/card-verified');
    if (marketerId && marketerId !== '') path += `&marketerId=${marketerId}`;
    if (subMarketerId && subMarketerId !== '') path += `&subMarketerId=${subMarketerId}`;
    window.parent.location.href = path;
  },
};
</script>

<style scoped>

</style>
