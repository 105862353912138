<template>
  <div>
    <Banner :imageList="bannerImages" type="home" v-if="!!middleBanner"/>
    <ContentLoading v-else type="banner" />
    <!-- <h1 class="flying-advertisement text-center mt-3 mb-0" v-if="!hideCondition">{{ $t("home.flying-advertisement")}}</h1> -->
    <TopBanner :topBanner="topBanner" v-if="topBanner.length > 0 && !hideCondition"/>

    <div v-if="!dealData && !hideCondition" class="loading-message">{{ $t('home.deal-loading-message') }}</div>
    <Lesiurpackages :promotionData="dealData" v-if="dealData && !hideCondition" />
    <ContentLoading v-else-if="!hideCondition" type="deal" />
    <Lesiurtabs
      :dealType="dealType"
      :dealData="dealData"
      :banner="middleBanner"
      :bannerHref="middleBannerHref"
      v-if="!!dealData && !!middleBanner && !hideCondition"
    />
    <Footer page="home" v-if="!hideCondition"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'Home',
  mixins: [getBannerImage],
  components: {
    Banner: () => import('@/components/content/Banner'),
    Lesiurpackages: () => import('@/components/home/Lesiurpackages'),
    Lesiurtabs: () => import('@/components/home/Lesiurtabs'),
    Footer: () => import('@/components/content/Footer'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    TopBanner: () => import('@/components/home/TopBanner'),
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      dealType: 'GET_DEAL_TYPE',
      dealData: 'GET_DEAL_DATA',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
    }),
    hideCondition() {
      const { isOdysseySite } = this;
      return isOdysseySite;
    },
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'home');
    if (!this.hideCondition) { await this.fetchDealData(); }
  },
  methods: {
    async fetchDealData() {
      await this.$store.dispatch('FETCH_DEAL_TYPE_LIST');
      await this.$store.dispatch('FETCH_DEAL_DATA');
    },
  },
};
</script>

<style scoped>
  .flying-advertisement {
    font-family: "ploni";
    font-weight: bold;
    font-size: 3rem;
    text-transform: capitalize;
  }

@media (max-width: 767px) {
  .flying-advertisement {
    font-size: 2rem;
    padding: 5px;
  }
}
</style>
