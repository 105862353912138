import { mapGetters } from 'vuex';

const tagOptions = ['active',
  'notActive',
  'noPaid',
  'paidPartly',
  'canceled'];

export default {
  computed: {
    ...mapGetters({
      currentPage: 'GET_CURRENT_PAGE',
      allAgentList: 'GET_ALL_AGENT_LIST',
    }),
    agentList() {
      const list = this.allAgentList;
      if (list.length === 0) return [];
      const returnList = list.map((elm, inx) => ({ id: inx + 1, value: elm.agt_Code, label: `${elm.agt_Code} - ${elm.agt_Name}` }));
      returnList.unshift({ id: 0, value: '', label: this.$t('pending-info.all') });
      return returnList;
    },
    clerkNameList() {
      const list = ['arturp', 'jennyy', 'milenat', 'annak', 'lenab', 'tatianak', 'ilanakag'].map((clerk, inx) => ({ id: inx + 1, value: clerk, label: clerk }));
      list.unshift({ id: 0, value: '', label: this.$t('pending-info.all') });
      return list;
    },
    filterOptions() {
      const op = tagOptions.map((tag) => ({ value: tag, text: this.$t(`pending-info.${tag}`) }));
      op.unshift({ value: '', text: this.$t('pending-info.all') });
      return op;
    },
  },
  data() {
    return {
      pageName: '',

      filterAgent: '',
      filterPnr: '',
      filterTag: '',
      filterClerkName: '',
    };
  },
  methods: {
    showFilterModal() {
      this.$bvModal.show('filterModal');
    },
    applyFilter() {
      if (this.currentPage === 'pending-info') {
        this.$store.commit('FILTER_PENDING_BOOKING_INFO', { pnr: this.filterPnr, agent: this.filterAgent, tag: this.filterTag, clerk: this.filterClerkName });
      }
      if (this.currentPage === 'booking-info') {
        this.$store.commit('FILTER_BOOKING_INFO', { pnr: this.filterPnr, tag: this.filterTag, clerkNameByAgent: this.filterClerkName });
      }
    },
  },
};
