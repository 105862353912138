<template>
  <div>
    <Banner v-if="bannerImages.length >0" :imageList="bannerImages" type="search-result" />
    <InforStrip :count="searchResultCount" :destination="destinationCityName" :date="dateString"/>

    <MobileFilter
      :sortButtonList="mobileSortButtonList"
      @updateSort="doSort"
      @filterChange="filterByOption"
      :filterOptions="filterOptions"
    />
    <SearchPopup />

    <div class="my-5 sportspage_area">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-12 filter-cont package-organize" v-if="isLoading && !timerEnd">
            <h3 class="text-center">{{ $t("search-result.just-another-moment") }}</h3>
            <div class="content text-center">
              <h3>{{ $t("search-result.domestic-search.search-loading-text2", {destinationName: destinationCityName})}}</h3>
            </div>
            <div class="circle" style="display: flex; justify-content: center; margin : auto">{{countDown}}</div>
            <h3 class="text-center">{{ $t("search-result.seconds") }}</h3>
            <ContentLoading />
          </div>
          <div class="col-lg-9 col-12 filter-cont package-organize" v-else-if="isLoading && timerEnd">
            <div class="content text-center">
              <h3>{{ $t("search-result.domestic-search.search-loading-time-end", {destinationName: destinationCityName})}}</h3>
            </div>
            <ContentLoading />
          </div>

          <div class="col-lg-9 col-12 filter-cont package-organize" v-else>
            <div class="desktop_form">
              <h3>
                {{ $t("search-result.properties-found", {count: filteredHotel.length}) }} : {{destinationCityName}}
              </h3>
              <hr />
              <SortButtons :buttonList="sortButtonList" @updateSort="doSort" />
            </div>

            <FilterItem v-for="(item, inx) in filteredHotel" :key="inx" :hotel="item" :itemIndex="inx"/>
          </div>
          <div class="col-lg-3 col-12 sideBar desktop_form">
            <filter-panel
              v-if="!isLoading"
              :options="filterOptions"
              @change="filterByOption"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer page="search" v-if="!hideCondition"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import getBannerImage from '@/mixins/getBannerImage';
import searchResultProductMixin from '@/mixins/searchResultProductMixin';

export default {
  name: 'SearchResult',
  components: {
    Banner: () => import('@/components/content/Banner'),
    InforStrip: () => import('@/components/searchResult/InforStrip'),
    // SidebarFilterSlide: () => import('@/components/searchResult/atoms/SidebarFilterSlide'),
    FilterPanel: () => import('@/components/searchResult/atoms/FilterPanel'),
    MobileFilter: () => import('@/components/searchResult/MobileFilter'),
    SearchPopup: () => import('@/components/searchResult/SearchPopup'),
    // SearchTab: () => import('@/components/search/SearchTab'),
    FilterItem: () => import('@/components/searchResult/atoms/FilterItem'),
    SortButtons: () => import('@/components/searchResult/atoms/SortButtons'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    Footer: () => import('@/components/content/Footer'),
  },
  data() {
    return {
      hotels: [],
      allHotels: [],
      filteredHotel: [],
      query: null,
      searchResultCount: 0,
      exCode: '',
      filterOptions: {},
      filterPriceValues: [],
      completedFilteredHotelOnly: [],
      sortButtonList: ['priceLTH', 'priceHTL'],
      mobileSortButtonList: ['priceLTH', 'priceHTL'],
      countDown: 40,
      timerEnd: false,
    };
  },
  mixins: [searchResultProductMixin, getBannerImage],
  computed: {
    ...mapGetters({
      packages: 'GET_PACKAGES',
      destinationList: 'GET_CAMINGO_DESTINATION',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      agentInfo: 'GET_LOGIN_AGENT_INFO',
      hotelList: 'GET_CAMINGO_HOTELS',
    }),
    destinationCityName() {
      const filterArray = this.destinationList.filter((item) => item.code === this.query.city);

      return filterArray.length > 0 ? filterArray[0].nameTranslations[this.lang] : '';
    },
    dateString() {
      const checkIn = dayjs(this.query.checkIn).format('D');
      const checkOut = dayjs(this.query.checkOut).format('D.M');
      return `${checkIn}-${checkOut}`;
    },
    hotelNameFilterOption() {
      // const { hotels, hotelList, lang } = this;
      const { hotels, hotelList } = this;
      if (!hotels || !hotels.length || !hotelList) return null;
      const noneDupHotelNames = [...new Set(hotels.map((item) => (item.name)).sort((a, b) => (a < b ? -1 : 1)))];
      // const noneDupHotelNames = [...new Set(hotels.map((item) => (hotelList.data.find((h) => h.id === item.hotelInfo?.hotelId).nameTranslations[lang] || item.name)).sort((a, b) => (a < b ? -1 : 1)))];
      const hotelNames = [...noneDupHotelNames.map((item) => ({ label: item }))];
      return {
        kind: 'hotel-name',
        title: this.$t('search-result.hotels'),
        subItems: hotelNames,
      };
    },
    hotelBasisFilterOption() {
      const { allHotels } = this;
      if (!allHotels || !allHotels.length) return null;
      let noneDupBasisNames = [
        // ...new Set(allHotels.map((item) => (this.$te(`basis-name.${item.basis.toLowerCase().trim()}`)
        //   ? this.$t(`basis-name.${item.basis.toLowerCase().trim()}`)
        //   : item.basisName))),
        ...new Set(allHotels.map((item) => ((item.basisName || item.basis).toLowerCase()))),
      ];

      noneDupBasisNames = noneDupBasisNames.filter((el) => el != null);
      const hotelBasis = [...noneDupBasisNames.map((item) => ({ label: item }))];
      return {
        kind: 'hotel-basis',
        title: this.$t('search-result.hosting-base'),
        subItems: hotelBasis,
      };
    },
    hideCondition() {
      const { isOdysseySite } = this;
      return isOdysseySite;
    },
  },
  watch: {
    packages: 'getHotels',
    $route: 'fetchData',
    filterPriceValues() {
      this.applyPriceRangeFilter();
    },
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'search-result');
    this.fetchData();
    if (this.isOdysseySite && !this.agentInfo) {
      this.$store.dispatch('FETCH_AGENT_INFO');
      this.$store.dispatch('FETCH_BIG_AGENT_INFO');
    }
    this.countDownTimer();
  },
  async mounted() {
    this.$root.$on('setTimer', () => {
      this.setCountDown();
    });
  },
  methods: {
    async fetchData() {
      this.countDown = 40;
      if (this.timerEnd) this.countDownTimer();
      this.completedFilteredHotelOnly = [];
      this.query = this.$route.query;

      if (!this.query.city || !this.query.checkIn || !this.query.checkOut || !this.query.adult || !this.query.suppliers) {
        this.$bvModal.msgBoxOk(
          this.$t('search-result.dont-remove-query'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return;
      }

      const body = {
        city: this.query.city,
        hotelCode: this.query.hotelCode,
        checkIn: this.query.checkIn,
        checkOut: this.query.checkOut,
        adult: Number(this.query.adult),
        child: Number(this.query.child),
        infant: Number(this.query.infant),
        lang: this.query.lang,
        includeFlight: this.query.includeFlight,
        suppliers: this.query.suppliers.split(','), // ['GOC', 'MINI', 'DAN', 'ISRO', 'ATLANTIS'],
        sources: this.query.sources,
        returnLog: this.query.returnLog,
      };

      this.exCode = this.query.exCode;

      localStorage.setItem('search-query', JSON.stringify(this.query));

      await this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', body);
      // document.querySelector('.st-content').scrollTo(0, 0);

      document.querySelector('.st-content').scrollTo(0, this.getUsableHeight());
    },
    getUsableHeight() {
      // check if this page is within a app frame
      const isInAppMode = ('standalone' in navigator && navigator.standalone) || (window.chrome && window.top.chrome.app && window.top.chrome.app.isInstalled);

      const ua = navigator.userAgent;
      // memoized values
      const isIphone = ua.indexOf('iPhone') !== -1 || ua.indexOf('iPod') !== -1;
      const isIpad = ua.indexOf('iPad') !== -1;
      const isAndroid = ua.indexOf('Android') !== -1;
      const isMobile = isIphone || isIpad || isAndroid;

      // compute the missing area taken up by the header of the web browser to offset the screen height
      let usableOffset = 0;
      if (isIphone) {
        usableOffset = 20;
      } else if (isAndroid && ua.indexOf('Chrome') === -1) {
        usableOffset = 1;
      }

      if (!isMobile) {
        return window.innerHeight;
      }
      const isLandscape = window.innerWidth > window.innerHeight;
      let height;
      // on ios devices, this must use screen
      if (isIphone) {
        height = isLandscape ? window.screen.width : window.screen.height;
        if (!isInAppMode) {
          height -= isLandscape ? 32 : 44;
          height += 1;
        }
      } else {
        height = (isLandscape ? window.outerWidth : window.outerHeight) / (window.devicePixelRatio || 1);
      }
      return height - usableOffset;
    },
    getHotels() {
      this.hotels = [];
      this.allHotels = [];
      if (this.packages && this.packages.length > 0) {
        this.packages.forEach((item) => {
          if (item.data.data && item.data.data.length) {
            item.data.data.forEach((elm) => {
              elm.supplierCode = item.supplierCode;
              elm.adminRemark = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].adminRemark : '';
              // elm.creditCardRemark = this.getCreditCardRemark(elm.remark);
              elm.rateRemarkForAgent = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode]?.rateRemarkForAgent || null : null;

              elm.isSpecialDiscounting = elm.matchingDiscInfo?.matchingDiscRuleInfo?.isSpecialDiscounting || false;

              elm.imgUrl = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].imageUrlInHotelConv : '';
              // eslint-disable-next-line no-nested-ternary
              elm.imgUrl = (elm.imgUrl) ? elm.imgUrl
                : (item.hotelInfo[elm.exCode]?.allImageUrlsInHotelInfo != null
                  && item.hotelInfo[elm.exCode].allImageUrlsInHotelInfo.length > 0
                  ? item.hotelInfo[elm.exCode].allImageUrlsInHotelInfo[0] : '');
              elm.hotelInfo = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode] : null;
            });

            let groupedHotels = {};
            if (this.exCode) {
              const hotelList = item.data.data.filter((elem) => elem.exCode === this.exCode);
              this.hotels.push(...hotelList);
            } else if (!this.isOdysseySite && item.supplierCode === 'ATLANTIS') {
              groupedHotels = [...item.data.data];
              if (groupedHotels) this.hotels.push(...groupedHotels);
            } else {
              item.data.data.forEach((elm) => {
                const { exCode } = elm;
                elm.priority = (item.hotelInfo[exCode]) ? item.hotelInfo[exCode].priority : Infinity;
                if (groupedHotels[exCode]) {
                  groupedHotels[exCode].push(elm);
                } else {
                  groupedHotels[exCode] = [];
                  groupedHotels[exCode].push(elm);
                }
              });
              // eslint-disable-next-line no-restricted-syntax
              for (const value of Object.entries(groupedHotels)) {
                const sortedArray = this.sortArray(value[1], 'totalAfterDiscounted');
                sortedArray[0].count = sortedArray.length;
                if (sortedArray[0]) {
                  sortedArray[0].moreHotels = sortedArray; // .slice(1);
                  this.hotels.push(sortedArray[0]);
                }
              }
            }
            this.allHotels.push(...item.data.data);
          }
        });
      }
      this.searchResultCount = this.hotels.length;
      // hotelInfo.{item}.discountPercent       ---> general discount
      // hotelInfo.{item}.isSpecialDiscounting  ---> special discount (yes/no)

      // data.data.discountPercent              ---> deal discount
      // data.data.isDealDiscounting            ---> deal discount (yes/no)
      this.filteredHotel = this.hotels;

      if (this.hotels.length === 0) {
        const h = this.$createElement;
        const messageVNode = h('div', { class: ['d-flex'] }, [
          h('i', { class: ['fa fa-exclamation-circle fa-red-icon'] }, []),
          h('p', { class: ['my-0'] }, [h('strong', `${this.$t('search-result.domestic-search.empty-hotel')}`)]),
        ]);
        this.$bvModal.msgBoxOk(
          [messageVNode],
          // `${this.$t('search-result.domestic-search.empty-hotel1')}, ${this.destinationCityName} ${this.$t('search-result.domestic-search.empty-hotel2')}`,
          {
            title: this.$t('booking.please-note'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            // bodyClass: this.lang === 'en' ? 'ltr' : 'text-right',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
      }
      this.updatePriceRangeFilterData();
      setTimeout(() => { this.filteredHotel = this.sortTotalHotel(this.hotels); }, 500);
    },
    sortArray([...arr], sortItem, order = 1) {
      arr.sort((a, b) => {
        if (Number(a[sortItem]) > Number(b[sortItem])) {
          return order;
        } else if (Number(a[sortItem]) < Number(b[sortItem])) {
          return -1 * order;
        }
        return 0;
      });
      return arr;
    },
    sortTotalHotel([...arr]) {
      const atlantisHotel = arr.filter((elm) => elm.supplierCode === 'ATLANTIS');
      const dealDiscountedHotels = arr.filter((elm) => elm.supplierCode !== 'ATLANTIS' && (elm.isDealDiscounting || elm.isSpecialDiscounting));
      const generalDiscountedHotels = arr.filter((elm) => elm.supplierCode !== 'ATLANTIS' && !elm.isDealDiscounting && !elm.isSpecialDiscounting && elm.discountPercent);
      const restHotels = arr.filter((elm) => elm.supplierCode !== 'ATLANTIS' && !elm.discountPercent);

      return [
        ...this.sortArray(atlantisHotel, 'discountPercent', -1),
        ...this.sortArray(this.sortByHotIcon(dealDiscountedHotels), 'totalAfterDiscounted'),
        ...this.sortArray(this.sortByHotIcon(generalDiscountedHotels), 'totalAfterDiscounted'),
        ...this.sortArray(restHotels, 'totalAfterDiscounted'),
      ];
    },
    sortByHotIcon([...arr]) {
      arr.sort((a, b) => {
        if ((a.isDealDiscounting && !!a.discountPercent) || !!a.isSpecialDiscounting) {
          return -1;
        } else if ((b.isDealDiscounting && !!b.discountPercent) || !!b.isSpecialDiscounting) {
          return 1;
        }
        return 0;
      });
      return arr;
    },
    updatePriceRangeFilterData() {
      let priceList = [];
      if (this.filteredHotel) {
        priceList = this.filteredHotel.length > 0 ? this.getPriceList(this.filteredHotel, 'totalAfterDiscounted') : [];
      }
      if (priceList.length > 1) {
        const minPrice = Number(priceList[0]);
        const maxPrice = Number(priceList[priceList.length - 1]);

        const priceFilter = {
          kind: 'slider',
          title: 'price',
          value: [minPrice, maxPrice],
          min: minPrice,
          max: maxPrice,
          marks: priceList,
          symbol: this.filteredHotel[0].cc,
        };
        this.$set(this.filterOptions, 'priceSlider', priceFilter);
        this.filterPriceValues = [minPrice, maxPrice];

        this.priceFilterItem = priceFilter;
      } else {
        this.$set(this.filterOptions, 'priceSlider', null);
      }

      // this.$set(this.filterOptions, 'hotelBasis', this.hotelBasisFilterOption);
      this.$set(this.filterOptions, 'hotelName', this.hotelNameFilterOption);
    },
    async applyPriceRangeFilter() {
      const result = await this.hotels.filter(
        (item) => Number(item.totalAfterDiscounted) >= this.filterPriceValues[0]
            && Number(item.totalAfterDiscounted) <= this.filterPriceValues[1],
      );
      this.filteredHotel = [...result];
    },
    getPriceList([...arr], props) {
      const list = arr.map((item) => Math.floor(item[props]));
      return [...new Set(list)].sort((a, b) => a - b);
    },
    async filterByOption(conditions) {
      let { hotels } = this;

      if (conditions.price) {
        this.filterPriceValues = conditions.price;
        await this.applyPriceRangeFilter();
        hotels = [...this.filteredHotel];
      }
      const result = hotels.filter((item) => {
        const cond1 = (conditions['hotel-name'] && conditions['hotel-name'].length) ? conditions['hotel-name'].indexOf(item.name.toLowerCase()) > -1 : true;
        const cond2 = (conditions['hotel-basis'] && conditions['hotel-basis'].length)
          ? (conditions['hotel-basis'].indexOf((item.basisName || item.basis).toLowerCase()) > -1
          || (item.moreHotels?.length && item.moreHotels.findIndex((h) => conditions['hotel-basis'].indexOf((h.basisName || h.basis).toLowerCase()) > -1) > -1)) : true;

        return cond1 && cond2;
      });
      this.filteredHotel = [...result];

      this.searchResultCount = this.filteredHotel.length;
      document.querySelector('.st-content').scrollTo(0, this.getUsableHeight());
    },
    doSort(arr) {
      this.completedFilteredHotelOnly = [];
      this.applySort(arr[0], arr[1]);
    },
    applySort(type, asc) {
      let sortArray = [];
      sortArray = this.filteredHotel || [];

      if (type === 'price') {
        if (asc) {
          sortArray.sort((a, b) => a.totalAfterDiscounted - b.totalAfterDiscounted);
        } else {
          sortArray.sort((a, b) => b.totalAfterDiscounted - a.totalAfterDiscounted);
        }
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.timerEnd = false;
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.timerEnd = true;
      }
    },
    setCountDown() {
      this.countDown = 40;
    },
  },
};
</script>

<style scoped>
@media (max-width: 479px) {
  .sportspage_area {
    margin: 0 !important;
  }
}
</style>

<style>
.filter-hotel .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  right: -0.5rem;
}

.dVacation-pack.filter-cont .filterItem:not(:first-child) {
  margin-top: 0px;
}

.modal.show .modal-dialog.noSearchResult {
  display: inline-block;
  top: calc(50vh - 100px);
}

.modal-dialog.noSearchResult .modal-body{
  text-align: center;
}

.content {
  font-size:  20px;
}

.circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 36px;
  color: #fff;
  text-align: center;
  background: red;
}
.fa-red-icon {
  color: red;
  font-size: 2rem;
}

@media (max-width: 479px) {
  .modal.show .modal-dialog.noSearchResult {
    display: flex;
    left: -10px;
    top: unset;
  }

  .content h3 {
    font-size: 20px !important;
  }

  .circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    background: red;
  }
}
</style>
