<template>
  <div>
    <Banner :imageList="bannerImages" type="home" v-if="!!middleBanner"/>
    <ContentLoading v-else type="banner" />
    <Footer page="home" v-if="!hideCondition"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'HotelChain',
  mixins: [getBannerImage],
  components: {
    Banner: () => import('@/components/content/Banner'),
    Footer: () => import('@/components/content/Footer'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_CAMINGO_DESTINATION',
      hotelList: 'GET_CAMINGO_HOTELS',
      hotelAllInfo: 'GET_HOTEL_ALL_INFO',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
    }),
    query() {
      const route = this.$route;
      return route.query;
    },
    id() {
      const { query } = this;
      return query.id;
    },
    hideCondition() {
      const { isOdysseySite } = this;
      return isOdysseySite;
    },
  },
  watch: {
    query() {
      document.querySelector('.st-content').scrollTo(400, 400);
    },
  },
  async created() {
    if (!this.hotelAllInfo.length) {
      await this.$store.dispatch('FETCH_ALL_HOTEL_INFO');
    }
    document.querySelector('.st-content').scrollTo(400, 400);
  },
};
</script>

<style scoped>

</style>
