@@ -0,0 +1,209 @@
<template>
  <div v-if="odysseyAgentState && !isMEMSHAKAgent">
    <Banner :banner="bannerImages"/>
    <div class="my-5 mx-1 hotelplusflight">
      <div class="container">
        <b-button variant="primary" class="mb-2" @click="showFilterModal">{{$t("search-result.filter")}}</b-button>

        <h1 class="text-center"> {{$t("booking-info.personal-area")}} </h1>

        <b-pagination
          v-model="currentRowPage"
          :total-rows="rows"
          :per-page="perPage"
          :first-text="$t('pending-info.first')"
          :prev-text="$t('pending-info.prev')"
          :next-text="$t('pending-info.next')"
          :last-text="$t('pending-info.last')"
          class="p-0"
        ></b-pagination>

        <b-table responsive striped sticky-header="600px" no-border-collapse outlined table-variant="light" head-variant="light"
        :per-page="perPage"
        :current-page="currentRowPage"
        :busy.sync="isBusy"
        :items="items" :fields="fields">

          <template #cell(hotelOrderNum)="row">
            <span v-if="!row.item.hotelSource && row.item.pdfUrlHotelVoucher">{{ $t('pending-info.confirmed') }}</span>
            <span v-if="row.item.hotelSource && row.item.hotelOrderNum !== null">{{ row.item.hotelOrderNum }}</span>
            <span v-else>{{ $t('pending-info.unconfirmed') }}</span>
          </template>

          <template #cell(pdfUrlHotelVoucher)="row">
            <div class="icon_box" v-if="row.item.pdfUrlHotelVoucher">
              <b-link
                :href="row.item.pdfUrlHotelVoucher"
                @click.prevent="downloadTickets(row.item.pdfUrlHotelVoucher, 'HotelVoucher')"
                ><img src="/assets/img/after-booking-icon18.png"
              /></b-link>
            </div>
          </template>

          <template #cell(clerkNameByAgent)="row">
            <div>{{ row.item.bookTransactionDesc.clerkNameByAgent }}</div>
          </template>
          <template #cell(odyAgentCodeByAgent)="row">
            <div>{{ row.item.bookTransactionDesc.odyAgentCodeByAgent }}</div>
          </template>
          <template #cell(destination)="row">
            <div>{{ row.item.destination }} - {{ row.item.destinationCode }}</div>
          </template>
          <template #cell(price)="row">
            <div>${{ row.item.price }} </div>
          </template>
          <template #cell(operatorTag)="row">
            <div style="color:green;" v-if="row.item.operatorTag === 'active'">{{ row.item.operatorTag }} </div>
            <div style="color:red;" v-else-if="row.item.operatorTag === 'canceled'">{{ row.item.operatorTag }} </div>
            <div v-else>{{ row.item.operatorTag }} </div>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{$t('pending-info.loading')}}</strong>
            </div>
          </template>

        </b-table>
      </div>
    </div>

    <b-modal id="filterModal" centered size="lg" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header="">
        <div class="d-flex flex-grow-1 align-items-baseline">
          <h3 class="m-auto">Filter</h3>
        </div>
      </template>
      <template>
        <b-form-group label="PNR:" label-for="input-3">
          <b-form-input v-model="filterPnr" type="text" class="agent-amount"></b-form-input>
        </b-form-group>
        <b-form-group :label="`${$t('booking.clerk-name')}:`">
          <v-select v-model="filterClerkName" :reduce="name => name.value" :options="clerkNameList" :clearable="false" :searchable="true">
          </v-select>
        </b-form-group>
        <b-form-group :label="`${$t('pending-info.tag-state')}:`">
          <b-form-select v-model="filterTag" :options="filterOptions">
          </b-form-select>
        </b-form-group>
      </template>
      <template #modal-footer="{ ok }">
        <b-button variant="success" @click="applyFilter(); ok();">
          {{ $t("booking.ok") }}
        </b-button>
        <b-button variant="danger" @click="ok();">
          {{ $t("login.cancel") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import { BTable, BFormGroup, BFormInput, BFormSelect, BLink, BPagination, BSpinner } from 'bootstrap-vue';
import 'vue-select/dist/vue-select.css';
import getProductBanner from '@/mixins/getProductBanner';
import filterBookingInfo from '@/mixins/filterBookingInfo';

export default {
  name: 'BookingInfoPage',
  mixins: [getProductBanner, filterBookingInfo],
  data() {
    return {
      fields: [
        // {
        //   key: 'tag',
        //   label: 'Option',
        // },
        {
          key: 'pnr',
          label: 'PNR',
        },
        {
          key: 'hotelOrderNum',
          label: 'Confirmation Number',
        },
        {
          key: 'hotelName',
          label: 'Hotel Name',
        },
        {
          key: 'clerkNameByAgent',
          label: 'clerk name',
        },
        {
          key: 'odyAgentCodeByAgent',
          label: 'Originator',
        },
        {
          key: 'destination',
          label: 'Destination',
        },
        {
          key: 'checkinDate',
          label: 'CheckIn',
        },
        {
          key: 'checkoutDate',
          label: 'CheckOut',
        },
        {
          key: 'price',
          label: 'Price',
        },
        {
          key: 'pdfUrlHotelVoucher',
          label: 'Hotel Voucher',
        },
        {
          key: 'operatorTag',
          label: 'Tag',
        },
      ],
      delayMinute: 13,
      // items: [],
      refreshIntervalId: null,
      currentIndex: null,

      perPage: 5,
      currentRowPage: 1,
    };
  },
  components: {
    BTable,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BFormGroup,
    BFormInput,
    vSelect,
    Banner: () => import('@/components/productPage/ProductBanner'),
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      allBookingInfoList: 'GET_BOOKING_INFO_LIST',
      filteredBookingInfo: 'GET_FILTERED_BOOKING_INFO_LIST',
      isBusy: 'GET_LOADING_BOOKING_INFO',
      odysseyAgentState: 'GET_ODYSSEY_AGENT_STATE',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
    }),
    rows() {
      return this.items.length;
    },
    items() {
      const { allBookingInfoList, filteredBookingInfo } = this;
      if (!allBookingInfoList) return [];
      return filteredBookingInfo || allBookingInfoList;
    },
  },
  watch: {
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'booking-info');
    if (!this.odysseyAgentState || this.isMEMSHAKAgent) {
      this.$router.push('/');
    } else {
      this.$store.dispatch('FETCH_BOOKING_INFO_LIST');
    }
  },
  methods: {
    downloadTickets(pURL, pName) {
      this.$bvToast.toast(this.$t('booking.waiting-downloading'), {
        title: this.$t('booking.downloading'),
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'info',
        noCloseButton: true,
        bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
      });
      const fileName = `${pName}.pdf`;
      if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = pURL;
        save.target = '_blank';
        save.download = fileName;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
          document.location = save.href;
        } else {
          const evt = new MouseEvent('click');
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      } else if (!!window.ActiveXObject && document.execCommand) {
        const _window = window.open(pURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName);
        _window.close();
      }
    },
  },
};
</script>

<style scoped>
 .icon_box {
    width: 40px;
    height: 40px;
    border: 1px solid #0763ab;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto 15px;
  }
  .icon_box img {
    width: 30px;
    height: 30px;
  }
</style>
