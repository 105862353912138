<template>
  <div class="wrapper-card">
    <div class="badge-information p-2 price">
      <span class="icon"><img src="/assets/img/after-booking-icon1.png" alt="" title="" /></span>
      {{ $t("booking.total-to-pay") }} : <strong>${{ price }}</strong>
    </div>
    <div style="min-height:180px; text-align:center;">
      <iframe id="verifyingCard" title="Card Verifying" :src="path1" v-show="loaded"></iframe>
      <img src="/assets/img/iframe-swimming-loading.gif" class="loading-gif" v-if="!loaded"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { fromBase64 } from '@/utils/base64Util';
import googleAnalytics from '@/mixins/googleAnalytics';

export default {
  mixins: [googleAnalytics],
  data() {
    return {
      loaded: false,
      product: null,
      price: 0,
      path1: '',
    };
  },
  computed: {
    ...mapGetters({
      path: 'GET_VERIFYING_URL',
      bookingDataID: 'GET_BOOKING_DATA_ID',
      marketerId: 'GET_MARKETER_ID',
      subMarketerId: 'GET_SUB_AGENCY',
    }),
  },
  created() {
    this.path1 = this.path || window.localStorage.getItem('VERIFYING_URL');
    window.localStorage.setItem('VERIFYING_URL', this.path1);
    if (document.querySelector('.st-content'))document.querySelector('.st-content').scrollTo(0, 0);

    this.product = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo'))).data;
    this.price = this.product.grandTotalAfterDiscount;
    const { marketerId, subMarketerId } = this;
    window.localStorage.setItem('marketer', JSON.stringify({ marketerId, subMarketerId }));
  },
  mounted() {
    const that = this;
    document.getElementById('verifyingCard').onload = () => {
      that.loaded = true;
      that.afterIframeLoaded();
    };

    // if (!window.location.host.includes('vercel')) {
    //   const bookingDataID = this.bookingDataID || window.localStorage.getItem('BookingDataID'),
    //     { hotel: { hotelName, cityName }, priceBeforeCoupon } = this.product;
    //   this.gtag('event', 'begin_checkout', {
    //     items: [
    //       {
    //         id: bookingDataID, // please assign number
    //         name: `${hotelName} - ${cityName}`,
    //         list_name: '',
    //         brand: '',
    //         category: '',
    //         variant: '',
    //         list_position: 1,
    //         quantity: 1,
    //         price: priceBeforeCoupon,
    //       },
    //     ],
    //   });
    // }
  },
  methods: {
    afterIframeLoaded() {
      // const { payer } = this.product;
      // const { identificationNumber, creditCardExpirMonth, creditCardExpirYear, noOfPayment } = payer;
      // const iframe = document.getElementById('verifyingCard');
      // const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
      // innerDoc.getElementById('paymentByCC').innerHtml = 'תשלום באמצעות כרטיס אשראי';
      // innerDoc.getElementById('credit_card_number_input').values = '375510290006779';
      // innerDoc.getElementById('date_year_input').value = creditCardExpirMonth;
      // innerDoc.getElementById('date_month_input').value = creditCardExpirYear;
      // innerDoc.getElementById('id_number_input').value = identificationNumber;
      // innerDoc.getElementById('cvv_input').value = noOfPayment;
    },
  },
};
</script>

<style scoped>

  #verifyingCard{
    width: 100%;
    height: 670px;
    border: none;
  }

  .wrapper-card{
    margin : auto;
    width: 100%;
    height: 100%;
  }

  .price{
    font-size : 30px;
  }
  .loading-gif {
    max-width: 100px;
    text-align: center;
    margin-top: 50px;
  }
</style>
