export default {
  methods: {
    getCreditCardRemark(remark) {
      let foundCardDeal = false;
      let typeCard = '';
      if (remark.includes('כרטיס') || remark.includes('ישראכרט') || remark.includes('לאומי') || remark.includes('ויזה') || remark.includes('אמריקן')) {
        if (remark.includes('כאל ')) {
          foundCardDeal = true;
          typeCard += 'כאל';
        }
        if (remark.includes('לאומי')) {
          foundCardDeal = true;
          typeCard += 'לאומי קארד ';
        }
        if (remark.includes('ויזה')) {
          foundCardDeal = true;
          typeCard += 'ויזה כאל ';
        }
        if (remark.includes('ישראכרט')) {
          foundCardDeal = true;
          typeCard += 'ישראכרט ';
        }
        if (remark.includes('אמריקן')) {
          foundCardDeal = true;
          typeCard += 'אמריקן אקספרס ';
        }
        if (remark.toLowerCase().includes('max')) {
          foundCardDeal = true;
          typeCard += 'MAX ';
        }
      }
      return foundCardDeal ? ` המחיר למחזיקי כרטיס אשראי ${typeCard}` : '';
    },
  },
};
