<template>
  <div>
    <BreadCrumb type="area" :data="linkData" v-if="linkData" />
    <Banner :banner="bannerImages" />
    <div class="my-5 hotelplusflight">
      <div class="container">
        <div class="row">
          <div class="col-md-9 filter-cont dVacation-pack" v-if="isLoading || hotels.length === 0">
            <ContentLoading type="product" />
          </div>
          <div class="col-md-9 filter-cont dVacation-pack" v-else>
            <div class="holiday-hotel-details">
              <h2>
                <!-- {{ categoryName }} {{ $t("product-page.for") }} -->
                {{ destinationCityName }} - {{ hotelName }}
              </h2>
            </div>
            <div class="clearfix"></div>
            <BreadCrumb type="flat" :stage="bookingStage" />
            <ReadMore :message="description"/>
            <div class="facilitie-list">
              <Facilitie v-for="(item, index) in facilities" :key="index" :data="item.name"/>
            </div>

            <div class="clearfix"></div>
            <Slider :slides="imageList" v-if="this.imageList.length" />

            <div class="hotelplusflight_bannerarea">
              <div class="hotelplusflight_body">
                <h2>{{ hotelName }}</h2>
                <div class="d-flex retingbox">
                  <span class="d-inline-block"><a href="#" ><i class="fas fa-map-marker-alt"></i>{{ destinationCityName }}</a></span>
                </div>
                <p class="d-block dated"><i class="far fa-calendar-alt"></i>{{ betweenDateString }}</p>
                <p><i class="far fa-user"></i>{{ personString }}</p>
                <p><i class="fas fa-coffee"></i>{{ basisName }}</p>
                <p v-if="remark"><i class="far fa-comment-dots"></i>{{ remark }}</p>
              </div>
            </div>
            <CollapseList :data="collapseData" v-if="collapseData.length > 0"/>

            <div class="chooseroom_area">
              <PriceArea :hotels="hotels" :moreHotelInfo="moreHotelInfo"
              :searchGuid="searchGuid" :supplier="supplier" :hotelName="hotelName"
              :destinationCityName="destinationCityName"/>
            </div>

            <div class="price-composition-desktop">
              <RoomComposition :information="description" />
            </div>
          </div>
          <Sidebar :hotelName="hotelName" :destination="destinationCityName" :remarks="remarks" />
        </div>
      </div>
    </div>
    <Footer page="product" v-if="!hideCondition"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import searchResultProductMixin from '@/mixins/searchResultProductMixin';
import getProductBanner from '@/mixins/getProductBanner';

export default {
  components: {
    Banner: () => import('@/components/productPage/ProductBanner'),
    BreadCrumb: () => import('@/components/productPage/Breadcrumb'),
    Sidebar: () => import('@/components/productPage/ProductSidebar'),
    ReadMore: () => import('@/components/productPage/atoms/Readmore'),
    Slider: () => import('@/components/productPage/ProductSlider'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    PriceArea: () => import('@/components/productPage/ProductPriceArea'),
    RoomComposition: () => import('@/components/productPage/RoomComposition'),
    CollapseList: () => import('@/components/productPage/CollapseList'),
    Facilitie: () => import('@/components/productPage/atoms/Facilitie'),
    Footer: () => import('@/components/content/Footer'),
  },
  data() {
    return {
      bookingStage: 1,

      personString: '',
      query: this.$route.query,
      hotels: [],
      hotelCode: '',
      imageList: [],
      description: '',
      facilities: [],
      remarks: '',
      hotelName: '',
      collapseData: [],

      searchGuid: '',
      supplier: '',
    };
  },
  mixins: [searchResultProductMixin, getProductBanner],
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      destinationList: 'GET_CAMINGO_DESTINATION',
      packages: 'GET_PACKAGES',
      lang: 'GET_LANGUAGE',
      moreHotelInfo: 'GET_ONE_HOTEL_INFO',
      marketerId: 'GET_MARKETER_ID',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      agentInfo: 'GET_LOGIN_AGENT_INFO',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
    }),
    linkData() {
      return {
        text: this.hotelName,
        dest: this.destinationCityName,
        stage: `${this.query.checkOut.split('-').reverse().join('/')} - ${this.query.checkIn.split('-').reverse().join('/')}`,
      };
    },
    destinationCityName() {
      const filterArray = this.destinationList.find((item) => item.code === this.query.city);

      return (filterArray) ? filterArray.nameTranslations[this.lang] : '';
    },
    betweenDateString() {
      let tripDuration = '';
      const checkIn = dayjs(this.query.checkIn, 'YYYY-MM-DD'), checkOut = dayjs(this.query.checkOut, 'YYYY-MM-DD');
      const duration = this.getDuration(checkIn, checkOut);
      if (this.lang === 'he') {
        tripDuration = `${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} -
           ${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} (${duration} ${this.$t('search-result.night')})`;
      } else {
        tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} -
            ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${duration} ${this.$t('search-result.night')})`;
      }
      return tripDuration;
    },
    basisName() {
      return this.hotels[0].basisName;
    },
    remark() {
      return this.supplier === 'ATLANTIS' ? this.hotels[0].remark : this.hotels[0].adminRemark;
    },
    hideCondition() {
      const { isOdysseySite } = this;
      return isOdysseySite;
    },
  },
  watch: {
    packages: 'getHotels',
    moreHotelInfo: 'setHotelInfo',
    lang() {
      this.personString = this.getPersonString(Number(this.$route.query.adult), Number(this.$route.query.child), Number(this.$route.query.infant));
      this.updatedCollapsData();
      this.setHotelInfo();
    },
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'product');
    this.hotelCode = this.query.hotelCode;
    this.supplierCode = this.query.suppliers;

    if (!this.query.city || !this.query.checkIn || !this.query.checkOut || !this.query.adult || !this.query.suppliers || !this.query.hotelCode) {
      this.$bvModal.msgBoxOk(
        this.$t('search-result.dont-remove-query'),
        {
          title: this.$t('product-page.expire-title'),
          dialogClass: 'noSearchResult',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        },
      );
      return;
    }
    const body = {
      city: this.query.city,
      hotelCode: String(this.hotelCode),
      checkIn: this.query.checkIn,
      checkOut: this.query.checkOut,
      adult: Number(this.query.adult),
      child: Number(this.query.child),
      infant: Number(this.query.infant),
      lang: this.query.lang,
      includeFlight: null,
      suppliers: [this.supplierCode],
      returnLog: null,
      dealCategoryId: this.query.dealCategoryId || null,
    };
    await this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', body);
    await this.$store.dispatch('FETCH_MORE_HOTEL_INFO', { hotelCode: this.hotelCode, supplierCode: this.supplierCode });
    await this.$store.dispatch('FETCH_CAMINGO_HOTELS');

    if (this.isOdysseySite && !this.agentInfo) {
      this.$store.dispatch('FETCH_AGENT_INFO');
      this.$store.dispatch('FETCH_BIG_AGENT_INFO');
    }
    window.localStorage.setItem('BookingDataID', '');
  },
  mounted() {
    this.personString = this.getPersonString(Number(this.$route.query.adult), Number(this.$route.query.child), Number(this.$route.query.infant));
  },
  methods: {
    getHotels() {
      this.hotels = [];
      if (this.packages && this.packages.length > 0) {
        this.packages.forEach((item) => {
          this.searchGuid = item.searchGuid;
          this.supplier = item.supplierCode;

          item.data.data.forEach((elm) => {
            if (elm.exCode === this.hotelCode) {
              elm.searchGuid = item.searchGuid;
              elm.adminRemark = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].adminRemark : '';
              elm.creditCardRemark = this.getCreditCardRemark(elm.remark);
              elm.imgUrl = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].imageUrl : '';
              this.hotels.push(elm);
            }
          });
        });
      }
      if (this.hotels.length === 0) {
        window.location.href = `hotel-info/?city=${this.query.city}&hotelCode=${this.query.hotelCode}`;
      }
      document.querySelector('.st-content').scrollTo(0, 0);
    },
    setHotelInfo() {
      this.imageList = this.moreHotelInfo.allImageUrls;
      if (this.query.mainimg) {
        let isExist = false;
        for (let i = 0; i < this.imageList.length; i += 1) {
          if (this.imageList[i] === this.query.mainimg) {
            isExist = true;
            break;
          }
        }
        if (!isExist) {
          this.imageList.unshift(this.query.mainimg);
        }
      }

      this.description = this.moreHotelInfo.hotelDescTrans[this.lang] || this.moreHotelInfo.hotelDescription;
      this.facilities = this.moreHotelInfo.facilities;

      this.remarks = this.moreHotelInfo.remark;
      this.hotelName = this.moreHotelInfo.hotelConversion.hotelName;

      this.imageDescList = this.moreHotelInfo?.roomClasses?.map((item) => ({
        src: item?.imageUrls?.[0],
        name: item.name,
        description: item.description,
      }));
      this.updatedCollapsData();
    },
    updatedCollapsData() {
      this.collapseData = [
        {
          title: this.$t('product-page.about-hotel-info'),
          message: this.description,
          type: 'html',
        },
        {
          title: this.$t('search-result.hotel-facilities'),
          fac: this.facilities,
          type: 'fac',
        },
        {
          title: this.$t('product-page.hotel-rooms'),
          type: 'image',
          items: this.imageDescList,
        },
        {
          title: this.$t('product-page.map'),
          type: 'map',
          address: `${this.destinationCityName} ${this.hotelName}`,
        },
        // {
        //   title: this.$t('product-page.general-terms'),
        //   type: 'html',
        //   message: this.description,
        // },
      ];
    },
    getDuration(checkIn, checkOut) {
      return dayjs(checkOut).diff(dayjs(checkIn), 'day');
    },
    getPersonString(adult, child, infant) {
      let str = (adult > 1) ? ` ${adult} ${this.$t('product-page.adults')}` : ` ${adult} ${this.$t('product-page.adult')}`;
      if (child) str += (child > 1) ? ` + ${child} ${this.$t('product-page.children')}` : ` + ${child} ${this.$t('product-page.child')}`;
      if (infant) str += (infant > 1) ? ` + ${infant} ${this.$t('product-page.infant')}` : ` + ${infant} ${this.$t('product-page.infants')}`;
      return str;
    },
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
  },
};
</script>

<style>
.modal {
  background-color: transparent;
}
.b-rating .b-rating-star,
.b-rating .b-rating-value {
  padding: 0 0.1em;
}
</style>

<style scoped>
.hotelplusflight {
  margin-top: 1em !important;
}
.retingbox .rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
  padding: 0;
  width: auto;
}
.clearfix {
  clear: both;
}
.hotelplusflight_body,
.mobail_part {
  display: none;
}
@media (max-width: 479px) {
  .holiday-hotel-details,
  .facilitie-list,
  .price-composition-desktop {
    display: none;
  }
  .hotelplusflight_body,
  .mobail_part {
    display: block;
  }
  .product-page-flight .filterItem {
    margin: 0;
  }
  .dVacation-pack {
    padding: 0;
  }
}
</style>
